import React from 'react';
import { EntityGrid } from 'store';
import { getLogsColDef } from './constants';
import CrawlerFilingsDialog from './CrawlerFilings';

const LogsTable = () => {
  const [selectedJobId, setSelectedJobId] = React.useState<string | null>(null);
  return (
    <>
      {!!selectedJobId && (
        <CrawlerFilingsDialog
          isOpen={!!selectedJobId}
          handleClose={() => setSelectedJobId(null)}
          jobId={selectedJobId}
        />
      )}
      <EntityGrid
        actionContent={<React.Fragment />}
        entityName="crawler_job"
        rows={[]}
        columns={getLogsColDef(setSelectedJobId)}
        searchableColumns={['name']}
        placeholder={'Search by name'}
        isServerHandled={true}
      />
    </>
  );
};

export default LogsTable;
