import React from 'react';
import { GridColumns } from '@material-ui/data-grid';
import { Statuses, StatusesColors } from 'store';
import { Chip } from 'ui-kit';
import { format } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import { Comment } from '@material-ui/icons';

export const getLogsColDef = (setSelectedJobId: (jobId: string) => void): GridColumns => [
  { field: 'id', headerName: 'id', width: 150, filterable: false },
  { field: 'name', headerName: 'Name', width: 400, filterable: true },
  {
    field: 'status',
    headerName: 'status',
    width: 150,
    filterable: false,
    renderCell: (cellParam) => {
      const status = cellParam.value as keyof typeof Statuses;
      const colors = StatusesColors[status as Statuses];
      return <Chip text={status} textColor={colors?.textColor} bgColor={colors?.bgColor} />;
    }
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 210,
    filterable: false,
    renderCell: (params) => format(new Date(params.row?.created_at), 'dd MMM yyyy HH:mm')
  },
  { field: 'job_id', headerName: 'job_id', width: 200, filterable: false },
  {
    field: 'details',
    headerName: 'Details',
    width: 200,
    filterable: false,
    renderCell: (params) => (
      <IconButton onClick={() => setSelectedJobId(params.row?.job_id)}>
        <Comment />
      </IconButton>
    )
  }
];
