import { getGridStringOperators, GridColumns } from '@material-ui/data-grid';
import { format } from 'date-fns';
import { Link } from '@material-ui/core';
import React from 'react';

export const getColDef = (): GridColumns => [
  {
    field: 'id',
    headerName: 'ID',
    width: 100
  },
  {
    field: 'period',
    headerName: 'Filing Period',
    width: 170,
    filterable: true,
    filterOperators: [getGridStringOperators().find((operator) => operator.value === 'equals')!]
  },
  {
    field: 'filing_type',
    headerName: 'Filing Type',
    width: 200,
    filterable: true
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
    renderCell: (params) => format(new Date(params.row?.created_at), 'dd MMM yyyy HH:mm')
  },
  {
    field: 'processed',
    headerName: 'Processed',
    width: 200,
    type: 'boolean'
  },
  {
    field: 'url',
    headerName: 'Sec URL',
    width: 200,
    renderCell: (params) => (
      <Link href={params.row.url} target={'_blank'}>
        {params.row.url}
      </Link>
    )
  }
];
