import React, { useEffect, useState } from 'react';
import { Dialog } from 'ui-kit';
import { EntityGrid } from 'store';
import { getColDef } from './constants';

interface CrawlerFilingsDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  jobId: string | null;
}

const CrawlerFilingsDialog: React.FC<CrawlerFilingsDialogProps> = ({
  isOpen,
  handleClose,
  jobId
}) => {
  const beforeClose = () => {
    handleClose();
  };

  return (
    <Dialog
      handleClose={beforeClose}
      isOpen={isOpen}
      hideDialogActions={true}
      title="Crawler Filings"
      width="lg"
      minHeight="600px"
    >
      <EntityGrid
        entityName={'crawler_job/filings'}
        rows={[]}
        columns={getColDef()}
        actionContent={[]}
        extraFilters={[{ job_id: jobId }]}
      />
    </Dialog>
  );
};

export default CrawlerFilingsDialog;
