import { GridFilterItem } from '@material-ui/data-grid';
import { TableOperatorsMap } from './constants';

export const buildFilterQueryParam = (
  filterConfig: GridFilterItem | null,
  searchableColumns: string[],
  extraFilters: { [key: string]: string }[],
  columnFiltrationConfigs: GridFilterItem[] | null
) => {
  let filterObject: { [key: string]: any } = {};
  if (filterConfig?.value && searchableColumns?.length > 0) {
    const filterValue = filterConfig?.value as string;
    searchableColumns.forEach((col) => {
      filterObject[`${col}__icontains`] = filterValue?.trim();
    });
  }

  const columnFilters: { [key: string]: any } = {};
  if (columnFiltrationConfigs) {
    columnFiltrationConfigs.forEach((columnFiltrationConfig) => {
      if (
        columnFiltrationConfig.columnField &&
        columnFiltrationConfig.operatorValue &&
        columnFiltrationConfig.value
      ) {
        let value = columnFiltrationConfig.value?.trim();
        if (['true', 'false'].includes(value)) {
          value = JSON.parse(value);
        }
        let operator = mapOperator(columnFiltrationConfig.operatorValue);
        if (operator) {
          operator = `__${operator}`;
        }
        columnFilters[`${columnFiltrationConfig.columnField}${operator}`] = value;
      }
    });
  }

  const extraFiltersObject: { [key: string]: any } = {};
  extraFilters.forEach((filter) => {
    Object.keys(filter).forEach((filterKey: string, index) => {
      if (extraFilters?.[index]?.[filterKey]) {
        extraFiltersObject[filterKey] = extraFilters?.[index][filterKey];
      }
    });
  });

  const filters = { ...extraFiltersObject, ...columnFilters, ...filterObject };
  return JSON.stringify(filters);
};

/**
 * Map the operator coming from the table to the operator Backend can understand
 * Ex:
 * table filter operator is Equals
 * Backend should receive Exact
 */
export const mapOperator = (filterOperator: string) => TableOperatorsMap[filterOperator];
